import React from 'react';
import { Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import clsx from 'clsx';

const IntroOneColumn = ({ fields, rendering }) => {
  const size = rendering?.params?.ColumnContentWidth || 'Wide';
  const showTheHairline = rendering?.params?.ShowTheHairline === '1' || false;

  const isSmall = size === 'Small';
  const isMedium = size === 'Medium';
  const isLarge = size === 'Wide';

  return (
    <div className="intro">
      <div className="container">
        <div className="row justify-content-center">
          <div
            role="main"
            aria-label="Content"
            className={clsx(
              isLarge && 'col-sm-12',
              isMedium && 'col-sm-12 col-md-9',
              isSmall && 'col-sm-12 col-md-9 residence-corporate-width'
            )}
          >
            {fields.Title.value !== '' && (
              <Text tag="h1" className="one-column" field={fields.Title} />
            )}
            {fields['Label 1'].value !== '' && fields['Address 1'].value !== '' && (
              <p
                className={clsx(
                  'address address-direction',
                  !showTheHairline && 'hide-address-hairline'
                )}
              >
                <Text tag="span" field={fields['Label 1']} />{' '}
                <a
                  href={'https://maps.google.ca/maps?q=' + fields['Address 1'].value}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Text field={fields['Address 1']} />
                </a>
              </p>
            )}
            {fields['Label 2'].value !== '' && fields['Address 2'].value !== '' && (
              <p className="address">
                <Text field={fields['Label 2']} />{' '}
                <a
                  href={'https://maps.google.ca/maps?q=' + fields['Address 2'].value}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Text field={fields['Address 2']} />
                </a>
              </p>
            )}
            <div className={clsx('content-one-column', isLarge && 'wide')}>
              <RichText field={fields.Copy} />
              <div className="cta">
                <Placeholder name="phCTA" rendering={rendering} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withDatasourceCheck()(IntroOneColumn);
